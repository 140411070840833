import React from "react";
import CompanyLeftNav from "../../../components/TwoColumnLeftNav/CompanyLeftNav";
import jcss from "../../../images/jcss_201903.png";
import LocalizedLink from "../../../components/localizedLink";
import ButtonLink from "../../../components/ButtonLink/ButtonLink";
import Footer from "../../../components/Footer/Footer";
import {LocaleContext} from "../../../components/layout";

const Jcss = () => {
  const { locale } = React.useContext(LocaleContext)
  const JcssJa = () => {
    return(
        <div className="content-container">
          {/* Heading First */}
          <h2 className={"large bold"}>
            計量法トレーサビリティ制度に基づく校正事業者の登録内容
          </h2>
          {/* first Section */}
          <div className="flex column gray inner jcss-first">
            <img src={jcss} alt="jcss 0110" className="jcss-logo"/>
            <div className="medium jcss-text">
              株式会社
              佐藤計量器製作所 宮城工場 校正技術課は、認定基準として ISO/IEC
              17025 を用い、 認定スキームを ISO/IEC
              17011に従って運営されているJCSSの下で認定されています。
            </div>
          </div>
          {/* Second Section */}
          <div className="flex column jcss-second">
            {/* second pc */}
            <div className="pc">
              <p className="medium jcss-text">
                JCSSを運営している認定機関（IA Japan）は、アジア太平洋認定協力機構（APAC）及び
                <LocalizedLink
                    className={"medium jcss-link"}
                    to={`https://ilac.org/`}
                    target={"_blank"} rel="noreferrer"
                >
                  国際試験所認定協力機構（ILAC）
                </LocalizedLink>
                の相互承認に署名しています。
              </p>
              <p className="medium jcss-text">
                株式会社
                佐藤計量器製作所 宮城工場 校正技術課は、国際MRA対応JCSS認定事業者です。
                JCSS 0110は、当校正技術課の認定番号です。
              </p>
              <p className="medium jcss-text">
                <LocalizedLink
                    className={"medium jcss-link"}
                    to={`https://static.sksato.co.jp/corporate/pdfs/JCSS_Q%26A.pdf`}
                    target={"_blank"} rel="noreferrer"
                >
                  JCSS校正サービスに関する弊社の取組みや最近の変更点などQ&A形式でまとめました。
                </LocalizedLink>
                [PDF]
              </p>
              <p className="medium jcss-text">
                ＊JCSS 計量法トレーサビリティ制度については
                <LocalizedLink
                    className={"jcss-link medium"}
                    to="https://www.nite.go.jp/iajapan/jcss/index.html"
                    target={"_blank"} rel="noreferrer"
                >
                  こちら
                </LocalizedLink>
                をご覧ください。
              </p>
            </div>
            {/* second sp */}
            <div className="sp">
              <ButtonLink
                  className={"margin-auto"}
                  text={"国際試験所認定協力機構"}
                  link={"https://ilac.org/"}
              />
              <p className="medium jcss-text">
                JCSSを運営している認定機関（IA Japan）は、アジア太平洋認定協力機構（APAC）及び国際試験所認定協力機構（ILAC）の相互承認に署名しています。
              </p>
              <p className="medium jcss-text">
                株式会社
                佐藤計量器製作所 宮城工場 校正技術課は、国際MRA対応JCSS認定事業者です。
                JCSS 0110は、当校正技術課の認定番号です。
              </p>
              <ButtonLink
                  className={"margin-auto"}
                  text={"JCSS校正サービス"}
                  link={
                    "https://static.sksato.co.jp/corporate/pdfs/JCSS_Q%26A.pdf"
                  }
              />
              <p className="medium jcss-text">
                JCSS校正サービスに関する弊社の取組みや最近の変更点などQ&A形式でまとめました。[PDF]
              </p>
              <ButtonLink
                  className={"margin-auto"}
                  text={"JCSS 計量法トレーサビリティ制度"}
                  link={`https://www.nite.go.jp/iajapan/jcss/index.html`}
              />
              <p className="medium jcss-text">
                ＊JCSS 計量法トレーサビリティ制度については
                こちらをご覧ください。
              </p>
            </div>
          </div>
          {/* Heading Second */}
          <h2 className={"large bold"}>温度・湿度のJCSS校正が可能です</h2>
          {/* third Section */}
          <div className="flex column jcss-third ">
            <ul>
              <li className="flex row">
                <div className="cert-title medium jcss-text ">認定番号</div>
                <div className="cert-content medium jcss-text">JCSS 0110</div>
              </li>
              <li className="flex row">
                <div className="cert-title medium jcss-text">
                  事業者の名称
                </div>
                <div className="cert-content medium jcss-text">
                  株式会社 佐藤計量器製作所
                  <br/>
                  宮城工場 校正技術課
                </div>
              </li>
              <li className="flex row">
                <div className="cert-title medium jcss-text">
                  事業所の所在地
                </div>
                <div className="cert-content medium jcss-text">
                  〒 989-1304
                  <br/> 宮城県柴田郡村田町大字村田字西ヶ丘２５－１
                </div>
              </li>
              <li className="flex row">
                <div className="cert-title medium jcss-text">
                  校正担当部署
                </div>
                <div className="cert-content medium jcss-text">
                  校正技術課
                </div>
              </li>
            </ul>
          </div>
          {/* fourth section */}
          <div className="flex jcss-fourth">
            {/* first table section */}
            <table>
              <thead>
              <tr>
                <th colSpan="2">校正対象品【校正量】</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td> &#9711; 指示計器付温度計</td>
                <td>【温度】</td>
              </tr>
              <tr>
                <td> &#9711; ガラス製温度計</td>
                <td>【温度】</td>
              </tr>
              <tr>
                <td> &#9711; 電子式温湿度計</td>
                <td>【温度】【相対湿度】【露点（演算値）】</td>
              </tr>
              <tr>
                <td> &#9711; 露点計</td>
                <td>【温度】【相対湿度（演算値）】【露点】</td>
              </tr>
              </tbody>
            </table>
          </div>
          {/* fifth section */}
          <div className="flex column jcss-fifth">
            <p className="medium">校正の範囲</p>
            {/* second table section */}
            <table>
              {/* table head section */}
              <thead>
              <tr>
                <th>登録区分</th>
                <th>校正手法の区分の呼称</th>
                <th>種類</th>
                <th>校正範囲</th>
                <th colSpan="2">条件</th>
              </tr>
              </thead>
              {/* table body section */}
              <tbody>
              {/* first row */}
              <tr>
                <td rowSpan="13" valign="top" align="center">
                  温度
                </td>
                <td rowSpan="13" valign="top" align="center">
                  接触式温度計
                </td>
                <td rowSpan="3" valign="top" align="center">
                  指示計器付温度計（比較校正法）
                </td>
                <td rowSpan="3" valign="top" align="center">
                  -80℃以上200℃以下
                </td>
                <td className="td">センサの種類</td>
                <td>白金抵抗温度計、熱電対、サーミスタ</td>
              </tr>
              {/* second row */}
              <tr>
                <td className="td">センサの形状</td>
                <td>下記校正条件補足1.を参照</td>
              </tr>
              {/* third row */}
              <tr>
                <td className="td">分解能</td>
                <td>
                  センサ：白金抵抗温度計
                  <br/>
                  0.01℃またはそれより低い
                  <br/>
                  （悪い）もの
                  <br/>
                  センサ：熱電対またはサーミスタ
                  <br/>
                  0.1℃またはそれより低い
                  <br/>
                  （悪い）もの
                </td>
              </tr>
              {/* fourth row */}
              <tr>
                <td rowSpan="3" valign="top" align="center">
                  指示計器付温度計（測定セルを用いた比較校正法）
                </td>
                <td rowSpan="3" valign="top" align="center">
                  0℃以上60℃以下
                </td>
                <td className="td">センサの種類</td>
                <td>白金抵抗温度計、サーミスタ</td>
              </tr>
              {/* fifth row */}
              <tr>
                <td className="td">センサの形状</td>
                <td>下記校正条件補足2.を参照</td>
              </tr>
              {/* sixth row */}
              <tr>
                <td className="td">分解能</td>
                <td>0.1℃またはそれより低い（悪い）もの</td>
              </tr>
              {/* seventh row */}
              <tr>
                <td rowSpan="3" valign="top" align="center">
                  指示計器付温度計（空気槽を用いた比較校正法）
                </td>
                <td rowSpan="3" valign="top" align="center">
                  0℃以上60℃以下
                </td>
                <td className="td">
                  センサの <br/>
                  種類
                </td>
                <td> 白金抵抗温度計、サーミスタ</td>
              </tr>
              {/* eighth row */}
              <tr>
                <td className="td">
                  センサの
                  <br/>
                  形状
                </td>
                <td>下記校正条件補足3.を参照</td>
              </tr>
              {/* ninth tr */}
              <tr>
                <td className="td">分解能</td>
                <td>0.1℃またはそれより低い（悪い）もの</td>
              </tr>
              {/* tenth row */}
              <tr>
                <td rowSpan="4" valign="top" align="center">
                  ガラス製温度計
                </td>
                <td rowSpan="4" valign="top" align="center">
                  0℃以上200℃以下
                </td>
                <td className="td">種類</td>
                <td>二重管式、全没式</td>
              </tr>
              {/* eleventh row */}
              <tr>
                <td className="td">封入液体</td>
                <td>水銀</td>
              </tr>
              {/* twelveth row */}
              <tr>
                <td className="td">形状</td>
                <td>
                  直線状のもの
                  <br/>
                  外径 11mm 以下
                  <br/>
                  全長 300 ～ 410mm
                </td>
              </tr>
              {/* thirteen row */}
              <tr>
                <td className="td">目量・目幅</td>
                <td>目量 0.1 ℃、目幅 0.3 ～ 0.6mm</td>
              </tr>
              {/* fourteen row */}
              <tr>
                <td rowSpan="7" valign="top" align="center">
                  湿度
                </td>
                <td rowSpan="7" valign="top" align="center">
                  湿度測定器等
                </td>
                <td rowSpan="3" valign="top" align="center">
                  露点計
                </td>
                <td valign="top" align="center">
                  露点－10℃以上20℃以下
                </td>
                <td className="td">対象機種</td>
                <td>光学式露点計</td>
              </tr>
              {/* fifteen row */}
              <tr>
                <td rowSpan="2" valign="top" align="center">
                  相対湿度30%以上90%以下（at23℃）
                </td>
                <td className="td">対象機種</td>
                <td>光学式露点計</td>
              </tr>
              {/* sixteen row */}
              <tr>
                <td className="td">機能</td>
                <td>
                  露点計本体の演算機能により相対湿度を表示するもの（付属の温度センサを使用）
                </td>
              </tr>
              {/* Seventeen row */}
              <tr>
                <td rowSpan="3" valign="top" align="center">
                  電子式湿度計
                </td>
                <td valign="top" align="center">
                  相対湿度10%以上90%以下at23℃）
                </td>
                <td className="td">対象機種</td>
                <td>
                  高分子電気抵抗式湿度計
                  <br/>
                  高分子電気容量式湿度計
                  <br/>
                  セラミックス電気抵抗式湿度計
                </td>
              </tr>
              {/* eighteen row */}
              <tr>
                <td rowSpan="2" valign="top" align="center">
                  露点－10℃以上20℃以下（at23℃）
                </td>
                <td className="td">対象機種</td>
                <td>
                  高分子電気抵抗式湿度計
                  <br/>
                  高分子電気容量式湿度計
                  <br/>
                  セラミックス電気抵抗式湿度計
                </td>
              </tr>
              {/* ninteenth row */}
              <tr>
                <td className="td">機能</td>
                <td>電子式湿度計本体の演算機能により露点を表示するもの</td>
              </tr>
              {/* twenty row */}
              <tr>
                <td rowSpan="1" valign="top" align="center">
                  電子式湿度計（恒温恒湿槽を用いた場合）
                </td>
                <td valign="top" align="center">
                  相対湿度10%以上90%以下（at23℃）
                </td>
                <td className="td">対象機種</td>
                <td>
                  高分子電気抵抗式湿度計
                  <br/>
                  高分子電気容量式湿度計
                  <br/>
                  セラミックス電気抵抗式湿度計
                </td>
              </tr>
              </tbody>
            </table>
            <p className="medium">
              *条件欄は目安です。弊社製品以外の校正も承ります。ご不明な点はお問い合わせください。
            </p>
          </div>
          {/* sixth section */}
          <div className="flex column jcss-sixth">
            <p className="medium jcss-text">校正条件補足</p>
            <div className="flex column gray jcss-sixth-item">
              <div className="flex column measure">
                  <span className="medium measure-txt jcss-text">
                    <span className="title">1. 指示計器付温度計</span>
                    （測定セル未使用）
                  </span>
                <ol type="a">
                  <li className="medium jcss-text">
                    指示計器付温度計であること（指示計器とセンサをセットで校正をします）
                  </li>
                  <li className="medium jcss-text">
                    センサは金属保護管（感温部）で、以下の条件を満たすこと
                    <br/>
                    0℃未満の校正：直径3.0/3.2/4.8/5.0mm 長さ200～500 mm
                    <br/>
                    （※装置の都合上、保護管に曲がりがあると校正ができない可能性があります）
                    <br/>
                    0℃以上の校正：直径8mm以下・長さ100～570 mm
                  </li>
                  <li className="medium jcss-text">
                    センサケーブルの長さが約1m以上あること
                  </li>
                </ol>
              </div>
              <div className="flex column measure ">
                  <span className="medium jcss-text">
                    <span className="title">2. 指示計器付温度計</span>
                    （測定セル使用）
                  </span>
                <ol type="a">
                  <li className="medium jcss-text">
                    指示計器付温度計であること（指示計器とセンサをセットで校正をします）
                    <br/>
                    （※直接液体に挿入することができない、電子式温湿度計などが対象となります）
                  </li>
                  <li className="medium jcss-text">
                    構造は以下のいずれかを満たすこと <br/>
                    ・センサ部全体で直径24mm以下・長さ300mm以下であること
                    <br/>
                    ・保護管等（感温部）が直径9mm以下・長さが320～500mmであること
                  </li>
                  <li className="medium jcss-text">
                    センサケーブルの長さが約1m以上あること
                  </li>
                </ol>
              </div>
              <div className="flex column measure">
                  <span className="medium jcss-text">
                    <span className="title"> 3. 指示計器付温度計</span>
                    （空気槽使用）
                  </span>
                <ol type="a">
                  <li className="medium jcss-text">
                    指示計器付温度計であること（指示計器とセンサをセットで校正をします）
                    <br/>
                    （※指示計器及びセンサ部全体を空気槽に設置して校正できるものが対象となります）
                  </li>
                  <li className="medium jcss-text">
                    下記の空間に設置可能な構造であること
                    <br/>
                    W440×H340×D340mm
                  </li>
                  <li className="medium jcss-text">
                    省電力機能（オートパワーオフなど）により画面表示（指示）が読み取り不可とならないものであること
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* seventh section */}
          <div className=" jcss-seventh">
            <div className="pc flex row row-to-column">
              <div className=" medium contact-title jcss-text">
                JCSS校正のお申し込みお問合せ先
              </div>
              <div className="medium  contact-content pc jcss-text">
                当社事業所一覧は
                <a
                    className={"medium jcss-link"}
                    href={`/company/office/`}
                    target={"_blank"} rel="noreferrer"
                >
                  こちら
                </a>
                <br/>
                <a className={"medium jcss-link"} href={`/support/`} target={"_blank"} rel="noreferrer">
                  JCSSに関するお問い合せは
                </a>
                こちらよりお願いいたします
              </div>
            </div>
            <div className="sp flex row row-to-column">
              <div className=" medium jcss-contact-title jcss-text">
                JCSS校正のお申し込みお問合せ先
              </div>
              <ButtonLink
                  className={"margin-auto sp"}
                  text={"当社事業所一覧"}
                  link={"/company/office"}
              />
              <ButtonLink
                  className={"margin-auto sp"}
                  text={"JCSSに関するお問い合せ"}
                  link={"/support"}
              />
            </div>
          </div>
        </div>
    )
  }
  const JcssEn = () => {
    return(
        <div className="content-container jcss-en">
          {/* Heading First */}
          <h2 className={"large bold"}>
            JCSS/MRA Accredited laboratory
          </h2>
          {/* first Section */}
          <div className="flex column gray inner jcss-first">
            <img src={jcss} alt="jcss 0110" className="jcss-logo"/>
            <div className="medium jcss-text">
              We, Calibration Engineering Section, Miyagi factory, Sato Keiryoki Mfg. Co., Ltd., are accredited with JCSS whose accreditation criteria is ISO / IEC 17025 and accreditation scheme is operated according to ISO / IEC 17011.
            </div>
          </div>
          {/* Second Section */}
          <div className="flex column jcss-second">
            {/* second pc */}
            <div className="pc">
              <p className="medium jcss-text">
                The accreditation body (IA Japan) which operates JCSS is a signatory to the multilateral arrangement of APAC and ILAC for the mutual recognition of calibration certificates.              </p>
              <p className="medium jcss-text">
                We, Calibration Engineering Section, Miyagi factory of Sato Keiryoki Mfg. Co., Ltd., are the JCSS accredited company. JCSS is corresponded to International MRA.              </p>
              <p className="medium jcss-text">
                Our accreditation No. JCSS 0110.
              </p>
            </div>
          </div>
          {/* Heading Second */}
          <h2 className={"large bold"}>Details of Accredited Scope in Calibration</h2>
          {/* third Section */}
          <div className="flex column jcss-third ">
            <ul>
              <li className="flex row">
                <div className="cert-title medium jcss-text ">Accreditation <br/>No.:</div>
                <div className="cert-content medium jcss-text">JCSS0110</div>
              </li>
              <li className="flex row">
                <div className="cert-title medium jcss-text">
                  Name of <br/>Organization:
                </div>
                <div className="cert-content medium jcss-text">
                  SATO KEIRYOKI MFG. CO., LTD.
                </div>
              </li>
              <li className="flex row">
                <div className="cert-title medium jcss-text">
                  Accredited <br/>Laboratory:
                </div>
                <div className="cert-content medium jcss-text">
                  Calibration Engineering Section, Miyagi Factory
                </div>
              </li>
              <li className="flex row">
                <div className="cert-title medium jcss-text">
                  Address of <br/>Laboratory:
                </div>
                <div className="cert-content medium jcss-text">
                  25-1, Aza Nishigaoka, Oaza Murata, Murata-machi, Shibata-gun, Miyagi-pref., 989-1304 JAPAN
                </div>
              </li>
              <li className="flex row">
                <div className="cert-title medium jcss-text">
                  Accreditation
                  <br/>
                  Scope:
                </div>
                <div className="cert-content medium jcss-text">
                  Temperature/Humidity
                </div>
              </li>
              <li className="flex row">
                <div className="cert-title medium jcss-text">
                  Accreditation <br/>Criteria:
                </div>
                <div className="cert-content medium jcss-text">
                  ISO/IEC 17025:2017
                </div>
              </li>
            </ul>
          </div>
          <div className="flex column jcss-fifth">
            <p className="medium">Calibration and Measurement Capabilities (Temperature)</p>
            <table>
              <thead>
              <tr>
                <th colSpan="2">
                  Calibration Procedures# and
                  <br/>
                  Type of Instruments/Materials
                  <br/>
                  to be calibrated
                </th>
                <th>Range</th>
                <th>
                  Expanded Uncertainty
                  <br/>
                  (Level of Confidence
                  <br/>
                  Approximately 95%)
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td rowSpan="13" align="center">
                  Contact
                  <br/>
                  Type
                  <br/>
                  Thermometer
                </td>
                <td rowSpan="6" align="center">
                  Temperature sensors with display unit
                  <br/>
                  (Comparison calibration)
                </td>
                <td align="center">From -80℃ less than -40℃</td>
                <td align="center">0.16℃</td>
              </tr>
              <tr>
                <td align="center">From -40℃ less than 0℃</td>
                <td align="center">0.09℃</td>
              </tr>
              <tr>
                <td align="center">From 0℃ up to 50℃</td>
                <td align="center">0.07℃</td>
              </tr>
              <tr>
                <td align="center">More than 50℃ up to 100℃</td>
                <td align="center">0.09℃</td>
              </tr>
              <tr>
                <td align="center">More than 100℃ up to 150℃</td>
                <td align="center">0.11℃</td>
              </tr>
              <tr>
                <td align="center">More than 150℃ up to 200℃</td>
                <td align="center">0.13℃</td>
              </tr>
              <tr>
                <td align="center">
                  Temperature sensors with display unit
                  <br/>
                  (Comparison calibration) (*1)
                </td>
                <td align="center">From 0℃ up to 60℃</td>
                <td align="center">0.2℃</td>
              </tr>
              <tr>
                <td align="center">
                  Temperature sensors with display unit
                  <br/>
                  (Comparison calibration) (*2)
                </td>
                <td align="center">From 0℃ up to 60℃</td>
                <td align="center">0.2℃</td>
              </tr>
              <tr>
                <td rowSpan="5">Liquid-in-glass thermometer</td>
                <td align="center">0℃</td>
                <td align="center">0.06℃</td>
              </tr>
              <tr>
                <td align="center">More than 0℃ up to 50℃</td>
                <td align="center">0.08℃</td>
              </tr>
              <tr>
                <td align="center">More than 50℃ up to 100℃</td>
                <td align="center">0.10℃</td>
              </tr>
              <tr>
                <td align="center">More than 100℃ up to 150℃</td>
                <td align="center">0.11℃</td>
              </tr>
              <tr>
                <td align="center">More than 150℃ up to 200℃</td>
                <td align="center">0.14℃</td>
              </tr>
              </tbody>
            </table>
            <p className="medium">
              (*1) Comparative calibration with cell
            </p>
            <p className="medium">
              (*2) Comparative calibration with temperature testing chambers
            </p>
            <p className="medium">
              #All Calibration Procedures are in-house procedures developed by this laboratory.
            </p>
          </div>
          <div className="flex column jcss-fifth">
            <p className="medium">
              Calibration and Measurement Capabilities (Humidity)
            </p>
            <table>
              <thead>
              <tr>
                <th colSpan="2">
                  Calibration Procedures# and
                  <br/>
                  Type of Instruments/Materials
                  <br/>
                  to be calibrated
                </th>
                <th>Range</th>
                <th>
                  Expanded Uncertainty
                  <br/>
                  (Level of Confidence
                  <br/>
                  Approximately 95%)
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td rowSpan="13" align="center">
                  Humidity
                  <br/>
                  Measuring
                  <br/>
                  Instrument,etc.
                </td>
                <td rowSpan="5" align="center">
                  Dew point
                  <br/>
                  hygrometers
                </td>
                <td align="center">Dew point from -10℃ less than 0℃</td>
                <td align="center">0.16℃</td>
              </tr>
              <tr>
                <td align="center">Dew point from 0℃ up to 20℃</td>
                <td align="center">0.13℃</td>
              </tr>
              <tr>
                <td align="center">Relative humidity from 30% up to 40% at calibration temperature 23℃</td>
                <td align="center">0.5%</td>
              </tr>
              <tr>
                <td align="center">Relative humidity more than 40% up to 60% at calibration temperature 23℃</td>
                <td align="center">0.8%</td>
              </tr>
              <tr>
                <td align="center">Relative humidity more than 60% up to 90% at calibration temperature 23℃</td>
                <td align="center">1.1%</td>
              </tr>
              <tr>
                <td rowSpan="8" align="center">
                  Electronic
                  <br/>
                  hygrometers
                </td>
                <td align="center">Relative humidity from 10% up to 40% at calibration temperature 23℃</td>
                <td align="center">0.5%</td>
              </tr>
              <tr>
                <td align="center">Relative humidity more than 40% up to 60% at calibration temperature 23℃</td>
                <td align="center">0.8%</td>
              </tr>
              <tr>
                <td align="center">Relative humidity more than 60% up to 90% at calibration temperature 23℃</td>
                <td align="center">1.1%</td>
              </tr>
              <tr>
                <td align="center">Relative humidity from 10% up to 50% at calibration temperature 23℃ (*1)</td>
                <td align="center">1.2%</td>
              </tr>
              <tr>
                <td align="center">Relative humidity more than 50% up to 70% at calibration temperature 23℃ (*1)</td>
                <td align="center">1.5%</td>
              </tr>
              <tr>
                <td align="center">Relative humidity more than 70% up to 90% at calibration temperature 23℃ (*1)</td>
                <td align="center">2.0%</td>
              </tr>
              <tr>
                <td align="center">Dew point from -10℃ less than 0℃ at calibration temperature 23℃</td>
                <td align="center">0.3℃</td>
              </tr>
              <tr>
                <td align="center">Dew point from 0℃ up to 20℃ at calibration temperature 23℃</td>
                <td align="center">0.2℃</td>
              </tr>
              </tbody>
            </table>
            <p className="medium">
              (*1) Calibration with temperature and humidity testing chambers
            </p>
            <p className="medium">
              #All Calibration Procedures are in-house procedures developed by this laboratory.
            </p>
          </div>
          <div className="contents-end">
            <div className="company">
              <p className="name bold">SATO KEIRYOKI MFG. CO., LTD.</p>
              <p>Tokyo Japan</p>
            </div>
            <div className="jcss-nite">
              <p className="nite">Japan Calibration Service System (National Institute of Technology and Evaluation)</p>
              <a href="http://www.nite.go.jp/en/iajapan/jcss/index.html">http://www.nite.go.jp/en/iajapan/jcss/index.html</a>
            </div>
          </div>
        </div>
    )
  }
  return (
    <>
      <article className="jcss two-column-left-nav">
        <CompanyLeftNav />
        <section className="main-content">
          {(locale === 'ja')?
              <JcssJa/>:
              <JcssEn/>
          }
        </section>
      </article>
      <Footer />
    </>
  );
};

export default Jcss;
